.box-content-white {
    background-color: #ffffff;
}

.font-semibold {
    font-weight: 500;
}

.font-bold {
    font-weight: 600;
}

/* Panel Basic Details Styles */
.panel-basic-details {
    width: 100%;
    align-self: flex-start;
    padding: 16px;
}

.panel-header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.panel-title {
    margin-right: 10px !important;
    color: #424242;
}

.panel-name-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    max-width: 100%;
}

.edit-button {
    padding: 5px !important;
    border-radius: 50% !important;
    background-color: rgba(75, 71, 71, 0.04) !important;
    transition: background-color 0.2s;
}

.edit-button:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
}

.detail-container {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 16px;
    width: 100%;
    align-self: flex-start;
    overflow: hidden;
}

.detail-row {
    padding: 8px 0;
}

.detail-row:not(:first-child) {
    border-top: 1px solid #e0e0e0;
}

.detail-label {
    margin-bottom: 6px;
}

@media (max-width: 768px) {
    .box-content-white {
        padding: 8px !important;
    }
    
    .detail-container {
        padding: 12px !important;
    }

    .panel-basic-details {
        padding: 8px;
    }
}

.panel-procedure-insert-update-modal {
    min-height: 200px;
}

.panel-procedure-insert-update-section {
    padding: 16px;
    background-color: #FAFAFA;
    border-radius:10px !important;
    width: auto !important;
    border: 1px solid #E0E0E0;
}


.panel-procedures-table {
    padding-top: 5px;
}