.popover {
    background: white;
    overflow-y: auto;
    width: 250px;
    height: 'auto';
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Lato';
    overflow-y: auto;
    position: absolute;
    pointer-events: auto;
    z-index: 1000;
}

.popover button.non-active {
    padding: 8px;
    cursor: pointer;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 10px;
    border-style: none;
    background-color: #ffffff;
    text-align: left;
    font-family: 'Lato';
    display: flex;
	align-items: center;
}

.popover button.non-active:hover {
    padding: 8px;
    cursor: pointer;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 10px;
    border-style: none;
    background-color: #f0f0f0;
    border-radius: 8px;
    text-align: left;
    font-family: 'Lato';
    display: flex;
	align-items: center;
}

/* Active list item */
.popover button.active {
    background-color: #24A7E0;
    color: #ffffff;
    font-weight: 500;
    font-family: 'Lato';
    border-radius: 8px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 8px;
    cursor: pointer;
    border-style: none;
    color: #ffffff;
    margin-top: 10px;
    text-align: left;
    display: flex;
	align-items: center;
    align-items: center;
}

.popover button.active .dot-element{
    margin-left: 8px;
    width: 10px;
	height: 10px;
	border-radius: 50%;
    background-color: #ffffff;
}

.popover button.non-active .dot-element{
    margin-left: 8px;
    width: 10px;
	height: 10px;
	border-radius: 50%;
    background-color: #E0E0E0;
}

.popover button.active .nav-items{
    margin-left: 12px;
    font-size: 12px;
    font-family: 'Lato';
}

.popover button.non-active .nav-items{
    margin-left: 12px;
    font-size: 12px;
    font-family: 'Lato';
}

.moduleset-container{
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.moduleset-container button.non-active{
    height: 35px;
    width: 'auto';
    background-color: #ffffff;
    font-family: "Lato";
    font-size: 13px;
    border:none;
    border-radius: 8px;
}

.moduleset-container button.non-active:hover{
    height: 35px;
    width: auto;
    background-color: #E0E0E0;
    font-family: "Lato";
    font-size: 13px;
    border:none;
    border-radius: 8px;
}

.moduleset-container button.active{
    height: 35px;
    width: auto;
    background-color: #24A7E0;
    font-family: "Lato";
    font-size: 13px;
    border:none;
    border-radius: 8px;
    color: #ffffff;
}

.moduleset-container button.active:hover{
    height: 35px;
    width: auto;
    background-color: #24A7E0;
    font-family: "Lato";
    font-size: 13px;
    border:none;
    border-radius: 8px;
    color: #ffffff;
}

.button-text{
    padding-left: 8px;
    padding-right: 8px;
}

.button-icon{
    padding-right: 8px;
}

@media (max-width: 890px) {
    .moduleset-container {
        all: unset; 
        width: 100%; 
    }
}